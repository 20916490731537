<template>
  <Toast />
  <Dialog
    v-model:visible="activarModal"
    :style="{ width: '950px' }"
    header="DETALLE PRODUCTOS"
    :modal="true"
    @hide="closeModal"
    class="p-fluid"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-2">
          <span><strong>PRODUCTO/SERVICIO:</strong></span>
          <Dropdown
            v-model="data_producto.tipo_catalogo"
            :options="tipos_catalogo"
            optionLabel="label"
            optionValue="value"
            @change="changeTipoCatalogo"
            :disabled="
              data_producto.id !== null && data_producto.id !== undefined
            "
          />
        </div>
        <div class="field col-12 md:col-2">
          <span
            ><strong>C&Oacute;DIGO INTERNO:</strong>
            <span class="p-invalid" v-if="facturar_producto == 1">*</span></span
          >
          <InputText
            placeholder="Código Interno"
            v-model="data_producto.codigo_interno"
          ></InputText>
          <small class="p-invalid" v-if="errors.codigo_interno">{{
            errors.codigo_interno[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-3" v-show="false">
          <span><strong>SUCURSAL: </strong></span>
          <Dropdown
            v-model="sucursalSelect"
            :options="sucursales"
            optionLabel="nombre"
            @change="changeSucursal"
          />
        </div>
        <div class="field col-12 md:col-5">
          <span
            ><strong>NOMBRE DE PRUDUCTO | SERVICIO: </strong>
            <span class="p-invalid">*</span></span
          >
          <Textarea
            placeholder="Descripción/Nombre del Producto"
            autofocus
            v-model="data_producto.descripcion"
            rows="1"
            autoResize
          />
          <small class="p-invalid" v-if="errors.descripcion">{{
            errors.descripcion[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-3">
          <span><strong>GRUPO: </strong> <span class="p-invalid">*</span></span>
          <div class="p-inputgroup">
            <Dropdown
              v-model="laboratorioSelect"
              :options="laboratorios"
              optionLabel="nombre"
              :filter="true"
              placeholder="Seleccione un Grupo"
            >
              <template #option="slotProps">
                <div class="p-dropdown-car-option">
                  <span>{{ slotProps.option.nombre }} </span>
                </div>
              </template>
            </Dropdown>
            <Button
              class="p-button"
              v-if="'Laboratorio Crear' in auth.user.permissions"
              icon="pi pi-plus"
              @click="activarLaboratorioModal"
            />
          </div>
          <small class="p-invalid" v-if="errors.fabrica_id">{{
            errors.fabrica_id[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-2">
          <span
            ><strong>UNIDAD DE MEDIDA: </strong>
            <span class="p-invalid">*</span></span
          >
          <div class="p-inputgroup">
            <Dropdown
              v-model="unidadSelect"
              :options="unidadesmedidas"
              :filter="true"
              optionLabel="nombre"
              placeholder="Seleccione una unidade medida"
            >
              <template #option="slotProps">
                <span> {{ slotProps.option.nombre }} </span>
              </template>
            </Dropdown>
            <Button
              class="p-button"
              v-if="'Unidad de Medida Crear' in auth.user.permissions"
              icon="pi pi-plus"
              @click="activarUnidadMedidaModal"
            />
          </div>
          <small class="p-invalid" v-if="errors.unidadmedida_id">{{
            errors.unidadmedida_id[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-2">
          <span><strong>PU VENTA: </strong></span>
          <div class="p-inputgroup">
            <InputNumber
              v-model="data_producto.precio_sugerido"
              :inputClass="'text-right'"
              :min="0"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              locale="de-ED"
              :disabled="data_producto.id && !actualizar_precio_venta"
            />
            <Button
              v-if="
                'Producto Actualizar' in auth.user.permissions &&
                data_producto.id
              "
              :disabled="!data_producto.id"
              class="p-button-info"
              icon="pi pi-pencil"
              @click="actualizar_precio_venta = !actualizar_precio_venta"
              v-tooltip.top="
                'Actualizar Precio de Venta en productos existentes'
              "
            />
          </div>
          <small class="p-invalid" v-if="errors.precio_sugerido">{{
            errors.precio_sugerido[0]
          }}</small>
        </div>
        <div
          class="field col-12 md:col-2 fondo mb-1"
          v-show="data_producto.tipo_catalogo == 1"
        >
          <span><strong>CANTIDAD: </strong></span>
          <InputNumber
            v-model="data_producto.stock_actual"
            showButtons
            :inputClass="'text-right'"
            :min="0"
            locale="de-ED"
            :disabled="!actualizar_precio_compra"
          />
        </div>
        <div class="field col-12 md:col-2">
          <span><strong>COMISI&Oacute;N CL&Iacute;NICA: </strong></span>
          <div class="p-inputgroup">
            <InputNumber
              v-model="data_producto.precio_compra"
              :inputClass="'text-right'"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              locale="de-ED"
              :disabled="!actualizar_precio_compra"
              v-tooltip.top="'Comisión Clínica'"
              :max="data_producto.precio_sugerido"
            />
            <Button
              v-if="
                'Producto Actualizar' in auth.user.permissions &&
                data_producto.id
              "
              class="p-button-info"
              icon="pi pi-pencil"
              @click="actualizar_precio_compra = !actualizar_precio_compra"
              v-tooltip.top="
                'Actualizar Precio de Compra en productos existentes'
              "
            />
          </div>
        </div>
        <div class="field col-12 md:col-2">
          <span><strong>M&Eacute;DICO: </strong> </span>
          <InputNumber
            :disabled="!actualizar_precio_compra"
            v-model="data_producto.comision_medico"
            :inputClass="'text-right'"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            locale="de-ED"
            v-tooltip.top="'Comisión Médico'"
            :max="data_producto.precio_sugerido - data_producto.precio_compra"
          />
        </div>
        <div
          class="field col-12 md:col-2"
          v-show="data_producto.tipo_catalogo == 2"
        >
          <span><strong>VENDEDOR: </strong> </span>
          <InputNumber
            :disabled="!actualizar_precio_compra"
            v-model="data_producto.comision_vendedor"
            :inputClass="'text-right'"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            locale="de-ED"
            v-tooltip.top="'Comisión Vendedor'"
            :max="
              data_producto.precio_sugerido -
              data_producto.precio_compra -
              data_producto.comision_medico
            "
          />
        </div>
        <div class="field col-12 md:col-2">
          <span><strong>SOLICITANTE: </strong> </span>
          <InputNumber
            :disabled="!actualizar_precio_compra"
            v-model="data_producto.comision_solicitante"
            :inputClass="'text-right'"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            locale="de-ED"
            v-tooltip.top="'Comisión Solicitante'"
            :max="
              data_producto.precio_sugerido -
              data_producto.precio_compra -
              data_producto.comision_medico -
              data_producto.comision_vendedor
            "
          />
        </div>
        <div
          class="grid col-12"
          v-show="'Producto Homologar' in auth.user.permissions"
        >
          <div class="field col-12 md:col-2 mt-3">
            <span
              v-if="data_producto.facturado == 1"
              v-tooltip.top="'Producto Homologado'"
              ><strong
                style="
                  background-color: green;
                  color: white;
                  border-radius: 5px;
                  padding: 5px;
                "
              >
                HOMOLOGADO: <i class="pi pi-check"></i></strong
            ></span>
            <span v-else
              ><strong>HOMOLOGAR: </strong>
              <Checkbox
                v-model="facturar_producto"
                :binary="true"
                v-tooltip.top="
                  data_producto.facturado == 1
                    ? 'Producto Homologado'
                    : 'Homologar Producto'
                "
                :disabled="data_producto.facturado == 1"
              />
            </span>
          </div>
          <div class="field col-12 md:col-10">
            <span><strong>SELECCIONE C&Oacute;DIGO SIAT: </strong></span>
            <Dropdown
              v-model="codigoSINSelect"
              :options="codigossin"
              :filter="true"
              optionLabel="descripcionProducto"
              placeholder="Seleccione un codigo siat"
            >
              <template #option="slotProps">
                <span
                  class="font-weight-bold"
                  style="font-size: 1em"
                  v-tooltip.top="slotProps.option.descripcionProducto"
                >
                  {{
                    slotProps.option.descripcionProducto.length > 80
                      ? slotProps.option.descripcionProducto.substring(0, 80) +
                        "..."
                      : slotProps.option.descripcionProducto
                  }}
                </span>
              </template>
            </Dropdown>
          </div>
        </div>
        <div class="field col-12 md:col-2" v-show="data_producto.id">
          <span><strong>ESTADO: </strong></span>
          <Dropdown
            v-model="data_producto.estado"
            :disabled="!data_producto.id"
            :options="estados"
            optionLabel="label"
            placeholder="Seleccione un estado"
          >
            <template #value="slotProps">
              <span
                :class="'product-badge ' + claseEstado[slotProps.value.value]"
              >
                {{ slotProps.value.label }}
              </span>
            </template>
          </Dropdown>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="field">
        <span class="flex md:justify-content-start"
          ><strong>CAMPOS REQUERIDOS: </strong>
          <span class="p-invalid">*</span></span
        >
      </div>
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="closeModal"
      />
      <Button
        :label="data_producto.id == null ? 'GUARDAR' : 'ACTUALIZAR'"
        icon="pi pi-save"
        class="p-button-info p-button-lg"
        @click="guardarProducto"
        :disabled="!enviado ? false : true"
        :loading="enviado"
      />
    </template>
  </Dialog>
  <UnidadMedidaCreate
    :show="UnidadMedidaModal"
    :unidadmedida="unidadMedida"
    @closeModal="cerrarModalUnidadMedida"
    @actualizarListado="cargarUnidadesMedidas"
  ></UnidadMedidaCreate>
  <LaboratorioCreate
    :mostrar="LaboratorioModal"
    :laboratorio="laboratorio"
    @closeModal="cerrarModalLaboratorio"
    @actualizarListadoLaboratorio="cargarLaboratorios"
  ></LaboratorioCreate>
</template>

<script>
import UnidadMedidaCreate from "@/module/unidadmedida/UnidadMedidaCreate.vue";
import LaboratorioCreate from "@/module/laboratorios/LaboratorioCreate.vue";
import ProductService from "@/service/ProductService";
import LaboratorioService from "@/service/LaboratorioService";
import UnidadmedidaService from "@/service/UnidadmedidaService";
import { useAuth } from "@/stores";
import SucursalService from "@/service/SucursalService";

export default {
  components: {
    UnidadMedidaCreate,
    LaboratorioCreate,
  },
  emits: ["closeModal", "actualizarListadoProductos"],
  props: {
    mostrar: {
      type: Boolean,
      default: false,
    },
    producto: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  productoService: null,
  laboratorioService: null,
  unidadmedidaService: null,
  sucursalService: null,
  auth: null,
  data() {
    return {
      activarModal: this.mostrar,
      data_producto: this.producto,
      laboratorios: {},
      unidadesmedidas: {},
      laboratorioSelect: {},
      unidadSelect: {},
      UnidadMedidaModal: false,
      LaboratorioModal: false,
      laboratorio: { estado: { label: "Activo", value: 1 } },
      unidadMedida: { estado: { label: "Activo", value: 1 } },
      estados: [
        { label: "Inactivo", value: 0 },
        { label: "Activo", value: 1 },
      ],
      enviado: false,
      errors: {},
      claseEstado: ["status-outofstock", "status-instock"],
      precio_sugerido: 0,
      actualizar_precio_compra: 0,
      actualizar_precio_venta: 0,
      sucursalSelect: null,
      sucursales: [],
      //si data_producto.facturado == 1, entonces facturar_producto = 1
      facturar_producto: 0,
      codigoSINSelect: { label: "Seleccione un codigo siat", value: null },
      unidadMedidaSelect: {},
      codigossin: [],
      tipos_catalogo: [
        { label: "PRODUCTO", value: 1 },
        { label: "SERVICIO", value: 2 },
      ],
    };
  },
  created() {
    this.auth = useAuth();
    this.productoService = new ProductService();
    this.laboratorioService = new LaboratorioService();
    this.unidadmedidaService = new UnidadmedidaService();
    this.sucursalService = new SucursalService();
  },
  mounted() {
    this.getDatosNecesariosProducto();
  },
  methods: {
    changeTipoCatalogo() {
      if (this.data_producto.tipo_catalogo != 1) {
        this.data_producto.stock_actual = 999999999;
        this.actualizar_precio_compra = true;
      } else {
        this.data_producto.stock_actual = 0;
        this.actualizar_precio_compra = true;
      }
    },
    getDatosNecesariosProducto() {
      this.productoService.getDatosNecesariosProducto().then((response) => {
        this.laboratorios = response.datos.fabricas ?? [];
        this.unidadesmedidas = response.datos.unidadmedida ?? [];
        this.codigossin = response.datos.codigossin ?? [];
        this.sucursales = response.datos.sucursales || [];
        this.sucursalSelect = this.sucursales[0].id || null;
      });
    },
    cargarCodigosSIN() {
      this.productoService
        .getCodigoSIN()
        .then((response) => {
          this.codigossin = response.codigossin || [];
          if (response.status == 200) {
            this.$toast.add({
              severity: "success",
              summary: "Exito",
              detail: response.message,
              life: 3000,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cargarSucursales() {
      this.sucursalService.getSucursalesAll().then((response) => {
        this.sucursales = response;
        this.sucursalSelect = this.sucursales[0];
      });
    },
    cerrarModalLaboratorio() {
      this.LaboratorioModal = false;
      this.laboratorio = {};
    },
    cerrarModalUnidadMedida() {
      this.UnidadMedidaModal = false;
      this.unidadMedida = {};
    },
    activarLaboratorioModal() {
      this.LaboratorioModal = true;
      this.laboratorio = {
        estado: { label: "Activo", value: 1 },
      };
    },
    activarUnidadMedidaModal() {
      this.UnidadMedidaModal = true;
      this.unidadMedida = {
        estado: { label: "Activo", value: 1 },
      };
    },
    cargarLaboratorios(fabrica = null) {
      this.laboratorioService
        .getAllLaboratorios()
        .then((response) => {
          this.laboratorios = response.fabricas;
          if (fabrica) {
            this.laboratorios.forEach((element) => {
              if (element.id == fabrica.id) {
                this.laboratorioSelect = element;
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cargarUnidadesMedidas(unidadmedida = null) {
      this.unidadmedidaService
        .getUnidadmedidasAll()
        .then((response) => {
          this.unidadesmedidas = response;
          if (unidadmedida) {
            this.unidadesmedidas.forEach((element) => {
              if (element.id == unidadmedida.id) {
                this.unidadSelect = element;
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    closeModal() {
      this.errors = {};
      this.laboratorioSelect = {};
      this.unidadSelect = {};
      this.activarModal = false;
      this.$emit("closeModal");
      this.actualizar_precio_compra = 0;
      this.sucursalSelect = this.sucursales[0];
      this.actualizar_precio_venta = 0;
      this.facturar_producto = 0;
      this.codigoSINSelect = {};
      this.unidadMedidaSelect = {};
      this.enviado = false;
    },
    guardarProducto() {
      if (
        this.facturar_producto == 1 &&
        this.data_producto.codigo_interno == null
      ) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Debe ingresar un codigo interno para el producto",
          life: 3000,
        });
        return;
      }
      this.errors = {};
      this.enviado = true;
      if (this.data_producto.id) {
        //Actualizar
        let tmp = {
          ...this.data_producto,
          estado: this.data_producto.estado.value,
          fabrica_id: this.laboratorioSelect.id,
          unidadmedida_id: this.unidadSelect.id,
          actualizar_precio_compra: this.actualizar_precio_compra ? 1 : 0,
          actualizar_precio_venta: this.actualizar_precio_venta ? 1 : 0,
          sucursal_id: this.sucursalSelect.id ?? null,
          stock_actual: this.data_producto.stock_actual ?? 0,
          /* facturar_producto: this.facturar_producto ? 1 : 0, */
          facturar_producto: this.data_producto.facturado
            ? 1
            : this.facturar_producto
            ? 1
            : 0,
          unidad_medida_sin: this.unidadMedidaSelect.id,
          codigo_siat:
            this.codigoSINSelect != null
              ? this.codigoSINSelect.codigoProducto
              : null,
        };

        this.productoService.actualizarProducto(tmp).then((response) => {
          if (Object.prototype.hasOwnProperty.call(response, "errors")) {
            this.errors = response.errors;
            this.$toast.add({
              severity: "error",
              summary: "Error!",
              detail: "Error al actualizar el producto",
              life: 3000,
            });
          } else {
            this.errors = {};

            this.$toast.add({
              severity: "success",
              summary: "Actualizado!",
              detail: response.mensaje,
              life: 3000,
            });
            this.$emit("actualizarListadoProductos");
            this.$emit("closeModal");
          }
          this.enviado = false;
        });
      } else {
        //Registrar nuevo producto
        if (this.laboratorioSelect == null) {
          this.$toast.add({
            severity: "error",
            summary: "Error!",
            detail: "Debe seleccionar un laboratorio",
            life: 3000,
          });
          this.enviado = false;
          return;
        }
        if (this.unidadSelect == null) {
          this.$toast.add({
            severity: "error",
            summary: "Error!",
            detail: "Debe seleccionar una unidad de medida",
            life: 3000,
          });
          this.enviado = false;
          return;
        }
        let tmp = {
          ...this.data_producto,
          estado: this.data_producto.estado.value,
          fabrica_id: this.laboratorioSelect.id,
          unidadmedida_id: this.unidadSelect.id,
          facturar_producto: this.facturar_producto ? 1 : 0,
          unidad_medida_sin: this.unidadMedidaSelect.id,
          codigo_siat:
            this.codigoSINSelect != null
              ? this.codigoSINSelect.codigoProducto
              : null,
          actualizar_precio_compra: this.actualizar_precio_compra ? 1 : 0,
          actualizar_precio_venta: this.actualizar_precio_venta ? 1 : 0,
          sucursal_id: this.sucursalSelect.id ?? null,
          stock_actual: this.data_producto.stock_actual ?? 0,
        };

        this.productoService.registrarProducto(tmp).then((response) => {
          if (Object.prototype.hasOwnProperty.call(response, "errors")) {
            this.errors = response.errors;
            this.$toast.add({
              severity: "error",
              summary: "Error!",
              detail: "Error al registrar el producto",
              life: 3000,
            });
          } else {
            this.errors = {};
            this.$toast.add({
              severity: "success",
              summary: "Exito!",
              detail: response.mensaje,
              life: 3000,
            });
            this.$emit("actualizarListadoProductos");
            this.$emit("closeModal");
          }

          this.enviado = false;
        });
      }
    },
    findIndex(id) {
      let index = -1;
      for (let i = 0; i < this.productos.length; i++) {
        if (this.productos[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    textoEstado(estado) {
      return estado == 1 ? "Activo" : "Inactivo";
    },
    changeSucursal() {
      let datos = {
        producto_id: this.data_producto.id,
        sucursal_id: this.sucursalSelect.id,
      };
      this.productoService.getProductoSucursal(datos).then((response) => {
        if (response.stock.length == 0) {
          this.data_producto.stock_actual = 0;
          this.data_producto.precio_compra = 0;
          this.data_producto.precio_sugerido =
            this.data_producto.precio_sugerido ?? 0;
          this.data_producto.ubicacion = this.data_producto.ubicacion ?? "";
          this.$toast.add({
            severity: "info",
            summary: "Cambio de sucursal!",
            detail: "Se cargaron los datos de la sucursal seleccionada",
            life: 3000,
          });
          return;
        }
        this.data_producto.stock_actual = parseFloat(
          response.stock[0].cantidad ?? 0
        );
        this.data_producto.precio_compra = parseFloat(
          response.stock[0].precio_compra ?? 0
        );
        this.data_producto.precio_sugerido = parseFloat(
          response.stock[0].precio_venta ?? 0
        );
        this.data_producto.ubicacion = response.stock[0].ubicacion ?? "";
        this.data_producto.pv2 = parseFloat(response.stock[0].pv2 ?? 0);
        this.data_producto.pv3 = parseFloat(response.stock[0].pv3 ?? 0);
        this.data_producto.pv4 = parseFloat(response.stock[0].pv4 ?? 0);
        this.data_producto.comision_medico = parseFloat(
          response.stock[0].comision_medico ?? 0
        );
        this.data_producto.comision_vendedor = parseFloat(
          response.stock[0].comision_vendedor ?? 0
        );
        this.data_producto.comision_solicitante = parseFloat(
          response.stock[0].comision_solicitante ?? 0
        );
        this.$toast.add({
          severity: "info",
          summary: "Cambio de sucursal!",
          detail: "Se cargaron los datos de la sucursal seleccionada",
          life: 3000,
        });
      });
    },
  },
  watch: {
    mostrar(newVal) {
      this.activarModal = newVal;
    },
    producto(newVal) {
      this.data_producto = newVal;
      // cuando es nuevo producto que cargue el primer elemento de cada lista que tenga el id 1
      if (this.data_producto.id == null) {
        this.laboratorioSelect = this.laboratorios.find(
          (fabrica) => fabrica.id == 1 ?? null
        );
        this.unidadSelect = this.unidadesmedidas.find(
          (unidad) => unidad.id == 1 ?? null
        );
        this.sucursalSelect = this.sucursales.find(
          (sucursal) => sucursal.id == 1 ?? null
        );
        this.data_producto.stock_actual = 999999999;
        this.actualizar_precio_compra = 1;

        return;
      }
      this.laboratorioSelect = this.laboratorios.find(
        (fabrica) => fabrica.id == this.data_producto.fabrica_id ?? null
      );

      this.unidadSelect = this.unidadesmedidas.find(
        (unidad) => unidad.id == this.data_producto.unidadmedida_id ?? null
      );
      this.codigoSINSelect = this.codigossin.find(
        (codigo) => codigo.codigoProducto == this.data_producto.codigo_siat
      );
      this.sucursalSelect = this.sucursales.find(
        (sucursal) => sucursal.id == 1 ?? null
      );

      this.actualizar_precio_compra = 0;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";

.p-invalid {
  color: red;
}
</style>
